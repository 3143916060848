/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.global-scope .ant-btn-round {
  font-size: 12px;
  font-weight: 500;
}
.global-scope .ant-btn-round.ant-btn-lg {
  font-size: 14px;
  font-weight: 500;
  height: 46px;
}
.global-scope .ant-btn-circle.ant-btn-lg {
  font-size: 14px;
  font-weight: 500;
  height: 46px;
  width: 46px;
  background-color: transparent;
  border-color: #507de3;
}
.global-scope .ant-btn-circle.ant-btn-lg.filled-circle-button {
  background-color: #f1f4ff;
  border-color: transparent;
  color: #507de3;
}
.global-scope .ant-btn-circle.filled-circle-button.btn-big {
  width: 60px;
  height: 60px;
  font-size: 20px;
}
.global-scope .contain-button {
  height: auto;
}
.global-scope .ant-btn-round.btn-largest {
  height: 60px;
  border-radius: 30px;
  font-size: 18px;
}
@media (min-width: 576px) {
  .global-scope .ant-btn-circle.filled-circle-button.btn-largest {
    min-width: 80px;
    min-height: 80px;
    width: 80px;
    height: 80px;
    font-size: 26px;
  }
}
@media (min-width: 768px) {
  .global-scope .ant-btn-circle.filled-circle-button.btn-largest {
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
    font-size: 16px;
  }
}
@media (min-width: 992px) {
  .global-scope .ant-btn-circle.filled-circle-button.btn-largest {
    min-width: 60px;
    min-height: 60px;
    width: 60px;
    height: 60px;
    font-size: 20px;
  }
}
@media (min-width: 1200px) {
  .global-scope .ant-btn-circle.filled-circle-button.btn-largest {
    min-width: 80px;
    min-height: 80px;
    width: 80px;
    height: 80px;
    font-size: 26px;
  }
}
.global-scope .ant-btn-circle.filled-circle-button.btn-borderd {
  border-color: #507de3;
}
.global-scope .ant-btn.ant-btn-circle.download-button {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.global-scope .ant-btn.ant-btn-circle.download-button span:not([role=img]) {
  margin-left: 0;
}
.global-scope .ant-form-item-explain {
  color: #3d3d3d;
  font-size: 12px;
  margin-top: 2px;
}
.global-scope .ant-form-item-label {
  margin-bottom: 4px;
  line-height: 1.5;
  white-space: normal;
  text-align: left;
}
.global-scope .ant-form-item {
  flex-direction: column;
}
.global-scope .ant-form-item.upload-files-form-item .ant-form-item-children-icon {
  margin-right: -30px;
  margin-top: 4px;
}
.global-scope .ant-select-dropdown {
  border-radius: 10px;
  padding: 6px 0;
}
.global-scope .ant-select.ant-select-single .ant-select-selector,
.global-scope .ant-select.ant-select-multiple .ant-select-selector {
  border-radius: 10px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  font-weight: bold;
  height: 38px;
  display: flex;
  align-items: center;
}
.global-scope .ant-select.ant-select-multiple .ant-select-selector {
  padding-left: 6px;
}
.global-scope .ant-select.ant-select-multiple .ant-select-selection-item {
  border-radius: 10px;
  font-weight: normal;
}
.global-scope .ant-select.ant-select-multiple .ant-select-selection-item-remove .anticon svg {
  fill: #ccc;
}
.global-scope .ant-select-selection,
.global-scope .ant-select-selection--single {
  border-radius: 10px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  font-weight: bold;
  height: 38px;
}
.global-scope .ant-select-selection .ant-select-selection__rendered,
.global-scope .ant-select-selection--single .ant-select-selection__rendered {
  line-height: 38px;
}
.global-scope .ant-calendar-range-picker-separator {
  vertical-align: unset;
}
.global-scope .ant-form-item-control-wrapper {
  width: 100%;
}
.global-scope .ant-form label,
.global-scope .ant-form-item-label label {
  font-size: 14px;
  font-weight: bold;
}
.global-scope .day-input {
  cursor: pointer;
  display: inline-flex;
  min-width: 120px;
}
.global-scope .ant-input,
.global-scope .ant-input-password,
.global-scope .ant-picker,
.global-scope .ant-picker-focused {
  border-radius: 10px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  font-weight: bold;
  height: 38px;
}
.global-scope .ant-input.text-field-readonly,
.global-scope .ant-input-password.text-field-readonly,
.global-scope .ant-picker.text-field-readonly,
.global-scope .ant-picker-focused.text-field-readonly {
  color: #3D3D3D;
}
.global-scope .ant-input.text-field-readonly_disabled,
.global-scope .ant-input-password.text-field-readonly_disabled,
.global-scope .ant-picker.text-field-readonly_disabled,
.global-scope .ant-picker-focused.text-field-readonly_disabled {
  cursor: not-allowed !important;
}
.global-scope .ant-input-affix-wrapper {
  border-radius: 10px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  font-weight: bold;
}
.global-scope .ant-input-suffix {
  display: flex;
  justify-content: center;
  align-items: center;
}
.global-scope .ant-input-prefix {
  display: flex;
  justify-content: center;
  align-items: center;
}
.global-scope .ant-input-password {
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
}
.global-scope .ant-input-password input.ant-input {
  height: auto;
  padding: 4px 11px;
}
.global-scope .ant-radio-checked .ant-radio-inner::after {
  display: block;
}
.global-scope .tag-input {
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: -6px;
}
.global-scope .tag-input .tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;
}
.global-scope .tag-input .tag .tag-container {
  border-radius: 10px;
  border: 1px solid #507de3;
  padding: 6px 12px;
  height: 38px;
  color: #507de3;
}
.global-scope .tag-input button {
  padding: 0 0 0 2px;
  height: unset;
  line-height: 1;
}
.global-scope .tag-input .add-tag {
  margin-bottom: 6px;
}
.global-scope .tag-input .add-tag button {
  height: 38px;
  width: 38px;
  margin-left: 10px;
  padding: 0;
}
.global-scope .tag-input .add-tag button span {
  display: flex;
  font-size: 16px;
  justify-content: center;
}
.global-scope .custom-radio.large .ant-radio-inner {
  width: 26px;
  height: 26px;
}
.global-scope .custom-radio.large .ant-radio-checked .ant-radio-inner::after {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  top: 4px;
  left: 4px;
}
.global-scope .react-tel-input.custom {
  display: inline-block;
  border-radius: 10px;
  border: none;
  height: 38px;
  line-height: 1;
}
.global-scope .react-tel-input.custom .form-control {
  width: 100%;
  border-radius: 10px;
  height: 38px;
  background: #fff;
}
.global-scope .react-tel-input.custom input {
  border: 0;
  font-weight: bold;
}
.global-scope .react-tel-input.custom .flag-dropdown,
.global-scope .react-tel-input.custom .flag-dropdown.open,
.global-scope .react-tel-input.custom .flag-dropdown:hover,
.global-scope .react-tel-input.custom .selected-flag {
  background-color: transparent !important;
  border: 0;
}
.global-scope .verification-code-input input {
  display: inline-block;
  border-radius: 10px !important;
  border: none !important;
  height: 38px !important;
  line-height: 1;
  caret-color: auto !important;
  width: 50px !important;
  margin-right: 5px;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}
.global-scope .verification-code-input input:last-child {
  margin-right: 0;
}
.global-scope .image-upload-field .ant-form-item-control {
  line-height: 1;
}
.global-scope .image-upload-field .ant-form-item-control .ant-spin-nested-loading {
  display: inline-block;
}
.global-scope .image-upload-field .ant-form-item-children-icon {
  display: none;
}
.global-scope .image-upload-field .ant-upload.ant-upload-select-picture-card {
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  position: relative;
  margin: 0;
  display: inline-block;
  width: auto;
  height: auto;
}
.global-scope .image-upload-field .ant-upload.ant-upload-select-picture-card .ant-upload {
  padding: 0 !important;
}
.global-scope .image-upload-field .ant-upload.ant-upload-select-picture-card .ant-upload img {
  border-radius: 10px;
  object-fit: contain;
}
.global-scope .image-upload-field .ant-upload.ant-upload-select-picture-card .image-upload-button {
  border-radius: 10px;
  opacity: 0;
  position: absolute;
  right: -1px;
  bottom: -1px;
  top: -1px;
  left: -1px;
  background-color: rgba(70, 113, 255, 0.8);
  transition: opacity 0.23s ease-in-out;
  cursor: pointer;
}
.global-scope .image-upload-field .ant-upload.ant-upload-select-picture-card .image-upload-button--active {
  opacity: 1;
  position: relative;
  top: 0;
  left: 0;
  margin: -1px;
}
.global-scope .image-upload-field .ant-upload.ant-upload-select-picture-card:hover .image-upload-button {
  opacity: 1;
}
.global-scope .image-upload-preview-field .ant-image-mask {
  border-radius: 10px;
  cursor: default;
}
.global-scope .image-upload-preview-field .ant-upload.ant-upload-select {
  border: 1px dashed #D9D9D9;
  transition: border 0.23s;
}
.global-scope .image-upload-preview-field .ant-upload.ant-upload-select:hover {
  border: 1px dashed #6E94FF;
  transition: border 0.23s;
}
.global-scope .image-upload-preview-field.ant-form-item-has-error .ant-upload.ant-upload-select:hover {
  border: 1px dashed #F9467E;
}
.global-scope .image-upload-preview-field .ant-form-item-children-icon {
  display: block;
  top: 16px !important;
}
.global-scope .image-upload-preview-field .upload-field-ref,
.global-scope .image-upload-preview-field .ant-image-img {
  line-height: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.global-scope .image-upload-preview-field .upload-field-blank {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
  user-select: none;
}
.global-scope .image-upload-preview-field .upload-field-blank__icon {
  margin-bottom: 0.5em;
}
.global-scope .image-upload-preview-field .upload-field-blank__text {
  text-align: center;
  line-height: 1.5em;
  padding: 0 1em;
  white-space: pre-wrap;
}
.global-scope .image-upload-preview-field .upload-preview-mask {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.global-scope .image-upload-preview-field .upload-preview-mask__button {
  display: flex;
  align-items: center;
  padding: 5px;
  line-height: 0;
}
.global-scope .image-upload-preview-field .upload-preview-mask__button span[role='button'] {
  margin-left: 5px;
}
.global-scope .numeric-field {
  display: flex;
  align-items: center;
  position: relative;
}
.global-scope .numeric-field__button {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.05);
  transition: transform 0.05s ease, background-color 0.3s ease;
  cursor: pointer;
  transition: 0.3s opacity;
}
.global-scope .numeric-field__button:hover {
  background-color: rgba(0, 0, 0, 0.15);
  transition: background-color 0.3s ease;
}
.global-scope .numeric-field__button:active {
  transform: scale(0.9);
  transition: transform 0.05s ease;
}
.global-scope .numeric-field__button_disabled {
  pointer-events: none;
  transition: 0.3s opacity;
  opacity: 0.4;
}
.global-scope .numeric-field__button-wrapper {
  position: absolute;
  z-index: 1;
  top: 7px;
  user-select: none;
}
.global-scope .numeric-field__button-wrapper_before {
  left: 9px;
}
.global-scope .numeric-field__button-wrapper_after {
  right: 9px;
}
.global-scope .numeric-field__button-wrapper_disabled {
  cursor: not-allowed;
}
.global-scope .numeric-field > input.ant-input {
  cursor: text;
  text-align: center;
  padding-left: 41px;
  padding-right: 41px;
}
.global-scope .numeric-field > .ant-input-affix-wrapper {
  display: flex;
  justify-content: center;
  z-index: 0;
  cursor: text;
  padding-left: 41px;
  padding-right: 41px;
}
.global-scope .numeric-field > .ant-input-affix-wrapper > input.ant-input {
  transition: width 0s;
}
.global-scope .numeric-field .ant-input-suffix {
  user-select: none;
}
.global-scope .select-tag-field > .ant-select-selector {
  height: auto !important;
  padding: 4px 32px 4px 6px !important;
}
.global-scope .select-tag-field > .ant-select-arrow {
  top: calc(50% + 1px);
  right: 14px;
}
.global-scope .select-tag-field > .ant-select-clear {
  font-size: 1em;
  right: 15px;
  margin-top: -7px;
}
.global-scope .select-tag-field .ant-select-selection-overflow-item-suffix {
  width: 0;
}
.global-scope .select-tag-field.ant-select-disabled > .ant-select-selector {
  cursor: not-allowed !important;
}
.global-scope .ant-picker-panel-container {
  border-radius: 10px;
}
.global-scope .ant-dropdown-menu {
  padding: 6px 0;
  border-radius: 10px;
}
.global-scope .success {
  color: #2ac642;
}
.global-scope .primary {
  color: #507de3 !important;
}
.global-scope .danger {
  color: #F9467E;
  fill: #F9467E;
}
.global-scope .danger-light {
  color: rgba(255, 71, 128, 0.17);
  fill: rgba(255, 71, 128, 0.17);
}
.global-scope .bg-danger-light {
  background-color: rgba(255, 71, 128, 0.17);
}
.global-scope .border-danger-light {
  border-color: rgba(255, 71, 128, 0.17);
}
.global-scope .border-transparent {
  border-color: transparent;
}
.global-scope .default {
  color: #3e3e3e;
}
.global-scope .ant-layout .ant-layout-header {
  display: flex;
  align-items: center;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  padding: 0;
}
.global-scope .ant-layout-content {
  padding: 38px 0;
  padding-top: 40px;
}
.global-scope .ant-layout-footer {
  text-align: right;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
  padding: 0;
  z-index: 2;
}
.global-scope .navigation-menu .navigation-item {
  min-width: auto;
  width: 100%;
  display: block;
  height: auto;
  white-space: normal;
  padding: 10px 15px 10px 0;
  border-radius: 0;
  border: none;
  margin-bottom: 24px;
}
.global-scope .navigation-menu .navigation-item .navigation-item-icon {
  stroke: #3e3e3e;
  fill: #3e3e3e;
}
.global-scope .navigation-menu .navigation-item .navigation-item-label {
  line-height: 1.2;
  margin-top: 8px;
  margin-bottom: 0;
  hyphens: auto;
}
.global-scope .navigation-menu .navigation-item .navigation-item-label-horizontal {
  margin-top: 4px;
}
.global-scope .navigation-menu .navigation-item:hover .navigation-item-label {
  color: #507de3;
}
.global-scope .navigation-menu .navigation-item:hover .navigation-item-icon {
  fill: #507de3;
  stroke: #507de3;
}
.global-scope .navigation-menu .navigation-item-horizontal {
  padding: 10px 0 10px 0;
  margin-bottom: 0;
}
.global-scope .navigation-menu .nav-link-horizontal {
  display: flex;
  flex: 1;
}
.global-scope .navigation-menu .nav-link-disabled {
  opacity: 0.5;
}
.global-scope .navigation-menu .selected .navigation-item {
  border-right: 3px #507de3 solid;
}
.global-scope .navigation-menu .selected .navigation-item .navigation-item-icon {
  stroke: #507de3;
  fill: #507de3;
}
.global-scope .navigation-menu .selected .navigation-item .navigation-item-label {
  color: #507de3;
}
.global-scope .navigation-menu .selected .navigation-item-horizontal {
  padding: 10px 10px 10px 10px;
  border-right: none;
  border-bottom: 3px #507de3 solid;
}
.global-scope .navigation-menu-horizontal {
  display: flex;
}
.global-scope .flexible {
  display: flex;
  align-items: center;
}
.global-scope .lg span,
.global-scope .lg.ant-checkbox-wrapper span {
  font-weight: bold;
}
.global-scope .lg .ant-checkbox .ant-checkbox-inner,
.global-scope .lg.ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
  height: 22px;
  width: 22px;
}
.global-scope .lg .ant-checkbox-checked .ant-checkbox-inner::after,
.global-scope .lg.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner::after {
  width: 8px;
  height: 14px;
}
.global-scope .view {
  display: flex;
}
.global-scope .ant-card {
  border-radius: 10px;
}
.global-scope .ant-card-meta {
  line-height: 1.3;
}
.global-scope .ant-card-meta-title {
  line-height: 1;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.25);
  font-weight: bold;
  font-size: 14px;
}
.global-scope .ant-card-meta-description {
  color: #3d3d3d;
  font-weight: bold;
  font-size: 16px;
}
.global-scope .header-page {
  font-size: 40px;
  font-weight: 900;
}
.global-scope .ant-upload.ant-upload-drag {
  border-radius: 10px;
  min-height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}
.global-scope .ant-spin-container.ant-spin-blur {
  overflow: visible;
}
.global-scope .ant-table {
  background: transparent;
}
.global-scope .ant-table .ant-table-container::after,
.global-scope .ant-table .ant-table-container::before {
  z-index: 4;
}
.global-scope .ant-table-wrapper .ant-pagination {
  float: right;
  margin-right: 10px;
}
.global-scope .ant-table-wrapper .ant-pagination-total-text {
  padding-left: 6px;
}
.global-scope .ant-table-placeholder {
  background: transparent;
  border: none;
}
.global-scope .ant-table-small {
  border: none;
}
.global-scope .table-rounded table {
  border-spacing: 0 5px;
}
.global-scope .table-rounded table thead .ant-table-cell {
  border-bottom: 0;
  background-color: white;
  color: gray;
}
.global-scope .table-rounded .ant-table-row .ant-table-cell {
  border-bottom: 0;
  background-color: #fafafa;
  font-size: 14px;
  font-weight: 900;
}
.global-scope .table-rounded .ant-table-row .ant-table-cell:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.global-scope .table-rounded .ant-table-row .ant-table-cell:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.global-scope .table-rounded .ant-table-row:hover .ant-table-cell {
  background-color: #f5f5f5;
}
.global-scope .table-rounded .ant-table-row-level-1 .ant-table-cell {
  background-color: transparent;
  border-bottom: 1px solid #fafafa;
}
.global-scope .table-rounded .ant-table-row-level-1 .indent-level-1 {
  padding: 0 !important;
}
.global-scope .table-rounded .ant-table-row-level-1 .ant-table-cell:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.global-scope .table-rounded .ant-table-row-level-1 .ant-table-cell:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.global-scope .table-rounded .ant-table-row.inactive {
  color: gray;
}
.global-scope .ant-collapse {
  background-color: transparent;
  border: 0;
}
.global-scope .ant-collapse .ant-collapse-item,
.global-scope .ant-collapse .ant-collapse-item:last-child {
  border: 0;
  margin-bottom: 5px;
}
.global-scope .ant-collapse .ant-collapse-item .ant-collapse-header,
.global-scope .ant-collapse .ant-collapse-item:last-child .ant-collapse-header,
.global-scope .ant-collapse .ant-collapse-item .ant-collapse-header:last-child,
.global-scope .ant-collapse .ant-collapse-item:last-child .ant-collapse-header:last-child {
  border: 0;
  border-radius: 10px;
  background-color: #fafafa;
  font-weight: bold;
}
.global-scope .ant-collapse .ant-collapse-item .ant-collapse-content,
.global-scope .ant-collapse .ant-collapse-item:last-child .ant-collapse-content {
  border: 0;
}
.global-scope .ant-drawer-header {
  border: 0;
}
.global-scope .ant-drawer-header .ant-drawer-title {
  margin-top: 30px;
}
.global-scope .ant-drawer-header .ant-drawer-close {
  background-color: #e9e9e9;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  top: 16px;
  right: 16px;
  line-height: 40px;
  padding: 0;
}
.global-scope .ant-drawer-body .drawer-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
}
.global-scope .ant-popover-inner {
  border-radius: 10px;
  overflow: hidden;
}
.global-scope .ant-popover-inner .footer {
  border-top: 1px solid #f9f9f9;
}
.global-scope .custom-ant-menu.ant-menu.ant-menu-root.ant-menu-inline {
  border-right: none;
}
.global-scope .custom-ant-menu.ant-menu.ant-menu-root.ant-menu-inline .ant-menu-submenu-title {
  border-radius: 10px;
  font-weight: 600;
}
.global-scope .custom-ant-menu.ant-menu.ant-menu-root.ant-menu-inline .ant-menu-submenu-title:hover {
  background-color: #fafafa;
}
.global-scope .custom-ant-menu.ant-menu.ant-menu-root.ant-menu-inline .ant-menu-item {
  border-radius: 10px;
  font-weight: 600;
}
.global-scope .custom-ant-menu.ant-menu.ant-menu-root.ant-menu-inline .ant-menu-item:first-child {
  margin-top: 0;
}
.global-scope .custom-ant-menu.ant-menu.ant-menu-root.ant-menu-inline .ant-menu-item:hover {
  background-color: #fafafa;
}
.global-scope .custom-ant-menu.ant-menu.ant-menu-root.ant-menu-inline .ant-menu-item-selected {
  background-color: #f5f5f5 !important;
}
.global-scope .custom-ant-menu.ant-menu.ant-menu-root.ant-menu-inline .ant-menu-item::after {
  display: none;
}
.global-scope .main-container {
  padding: 10px 0;
}
.global-scope .main-container .ant-table-content {
  overflow-x: auto;
}
.global-scope .main-container.with-navigation {
  padding-left: 20px;
}
.global-scope .form-container {
  background-color: rgba(231, 237, 255, 0.6);
  max-width: 500px;
  min-height: 430px;
  border-radius: 15px;
  padding: 40px;
  flex: 1;
}
.global-scope .form-container .form-header {
  display: flex;
  margin-bottom: 20px;
}
.global-scope .form-container .form-header .form-title-container {
  flex: 1;
  width: 258px;
  padding-right: 20px;
}
.global-scope .form-container .form-header .form-title-container .form-title {
  font-size: 20px;
  line-height: 1.3;
}
.global-scope .form-container .form-header .form-step {
  line-height: 0.9;
}
.global-scope .form-container .form-header .form-step .form-step-active {
  color: #507de3;
  font-weight: 900;
  font-size: 58px;
}
.global-scope .form-container .form-header .form-step .form-step-total {
  font-size: 31px;
  font-weight: 900;
}
.global-scope .form-container .ant-legacy-form-item-control-wrapper {
  flex: 0 1 auto;
}
.global-scope th.column-numeric,
.global-scope td.column-numeric {
  text-align: right !important;
}
.global-scope .ant-table tbody tr td.column-vertical-fill {
  padding-top: 0;
  padding-bottom: 0;
}
.global-scope th.no-border,
.global-scope td.no-border {
  border: 0 !important;
}
.global-scope .timeline {
  margin-left: 120px;
}
.global-scope .timeline .amount {
  display: inline-block;
  width: 100px;
  text-align: right;
  margin-left: -136px;
  font-weight: bold;
  margin-right: 50px;
}
.global-scope .timeline .timeline-item-date .ant-timeline-item-content {
  width: 200px;
  text-align: center;
  margin-left: -95px;
  font-weight: bold;
  background-color: white;
}
.global-scope .timeline .timeline-item-date .ant-timeline-item-tail {
  height: 100%;
  top: 0;
}
.global-scope .timeline .timeline-item-date .ant-timeline-item-head {
  opacity: 0;
}
.global-scope .timeline .ant-timeline-item .day {
  position: absolute;
  left: -100px;
  width: 72px;
  text-align: right;
}
.global-scope .timeline .timeline-item-date-grouped {
  padding-bottom: 10px;
}
.global-scope .block {
  padding: 24px;
  border-radius: 15px;
}
.global-scope .block .block-secondary {
  background-color: rgba(208, 208, 208, 0.14);
}
.global-scope .square {
  border-radius: 0;
}
.global-scope .block.block-primary {
  background-color: #f1f4fe;
}
.global-scope .block.block-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.global-scope .margin-bottom-05 {
  margin-bottom: 6px;
}
.global-scope .margin-bottom {
  margin-bottom: 12px;
}
.global-scope .margin-bottom-2x {
  margin-bottom: 24px;
}
.global-scope .cancel-margin-bottom {
  margin-bottom: 0 !important;
}
.global-scope .margin-top {
  margin-top: 12px;
}
.global-scope .margin-top-2x {
  margin-top: 24px;
}
.global-scope .margin-left {
  margin-left: 12px;
}
.global-scope .margin-left-2x {
  margin-left: 24px;
}
.global-scope .margin-right {
  margin-right: 12px;
}
.global-scope .margin-right-2x {
  margin-right: 24px;
}
.global-scope .light-form .ant-form-item-label label {
  color: #475061;
}
.global-scope .light-form .ant-input-password input.ant-input {
  padding-left: 0;
}
.global-scope .light-form .ant-input,
.global-scope .light-form .ant-input-affix-wrapper {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  border-bottom: solid 1px #d9d9d9;
  padding-left: 0;
  padding-right: 0;
}
.global-scope .light-form .react-tel-input.custom {
  display: inline-block;
}
.global-scope .light-form .react-tel-input.custom .form-control {
  width: 100%;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  border-bottom: solid 1px #d9d9d9;
  height: 38px;
}
.global-scope .light-form .react-tel-input.custom .flag-dropdown,
.global-scope .light-form .react-tel-input.custom .flag-dropdown.open,
.global-scope .light-form .react-tel-input.custom .flag-dropdown:hover,
.global-scope .light-form .react-tel-input.custom .selected-flag {
  background-color: transparent;
  border: 0;
}
.global-scope .light-form .has-error .input {
  border-color: #F9467E;
}
.global-scope .light-form .has-error .react-tel-input.custom .form-control {
  border-color: #F9467E;
}
.global-scope .bordered-form .ant-form-item-label label {
  color: #475061;
}
.global-scope .bordered-form .ant-input,
.global-scope .bordered-form .ant-input-affix-wrapper {
  border-radius: 10px;
  border: solid 1px #d9d9d9;
  height: 38px;
  padding-top: 0;
  padding-bottom: 0;
}
.global-scope .bordered-form .ant-input input,
.global-scope .bordered-form .ant-input-affix-wrapper input {
  height: auto;
}
.global-scope .bordered-form .ant-input-affix-wrapper .ant-input {
  border: none;
}
.global-scope .bordered-form .ant-select.ant-select-single,
.global-scope .bordered-form .ant-picker {
  border-radius: 10px;
  border: solid 1px #d9d9d9;
}
.global-scope .bordered-form .react-tel-input.custom {
  border: solid 1px #d9d9d9;
}
.global-scope .bordered-form .react-tel-input.custom .form-control {
  height: 36px;
}
.global-scope .bordered-form .has-error .input {
  border-color: #F9467E;
}
.global-scope .bordered-form .has-error .react-tel-input.custom .form-control {
  border-color: #F9467E;
}
.global-scope .light-input-bordered-form .ant-input {
  border: 0.2px solid #d9d9d9 !important;
  font-weight: normal !important;
}
.global-scope .with-descriptions-bold-label .ant-descriptions-item-label.ant-descriptions-item-colon {
  font-weight: 700;
}
.global-scope .clickable-row-table .ant-table-row {
  cursor: pointer;
}
.global-scope .expandable-separators-table table tbody .ant-table-expanded-row-level-1 td.ant-table-cell {
  padding: 0;
  height: 8px;
  background: transparent;
  border: none;
}
.global-scope .expandable-separators-table table tbody .ant-table-expanded-row-level-1:last-child td.ant-table-cell {
  height: 0;
}
.global-scope .nowrap-thead-table thead th {
  white-space: nowrap;
}
.global-scope .select-payment > .ant-select-selector {
  background-color: #fff;
  border: none;
  border-radius: 10px;
  padding: 5px;
}
.global-scope .select-payment > .ant-select-selector > .ant-select-selection-item {
  background-color: #507de3;
  border: none;
  border-radius: 6px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}
.global-scope .ant-select-multiple .ant-select-selection-item-remove svg {
  fill: #fff;
  width: 12px;
  height: 12px;
  position: relative;
  top: 1px;
}
.global-scope .flat-footer {
  background-color: white;
  position: sticky;
  padding: 10px 30px;
  margin-top: 55px;
  bottom: 40px;
  height: 66px;
  border-radius: 40px;
  -webkit-box-shadow: 0 0 51px 24px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 51px 24px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 51px 24px rgba(0, 0, 0, 0.05);
}
@media (max-width: 767px) {
  .global-scope .flat-footer {
    bottom: 70px;
  }
}
.global-scope .padding {
  padding: 24px;
}
.global-scope .padding-2x {
  padding: 48px;
}
.global-scope .padding-none {
  padding: 0;
}
.global-scope .table-group > :not(:first-child) {
  margin-top: 10px;
}
.global-scope .table-group > :not(:first-child) thead {
  visibility: collapse;
}
.global-scope .not-found {
  background-color: #fbfbfb !important;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  font-size: 24px;
  font-weight: 500;
  min-height: 100%;
  border-radius: 20px;
}
.global-scope .country-picker .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px;
}
.global-scope .mw-300 {
  max-width: 300px;
}
.global-scope .text-overflow-ellipsis {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.global-scope .ant-checkbox-group .ant-checkbox-group-item:not(:first-child) {
  margin-top: 4px;
}
@media (max-width: 767px) {
  .global-scope .hidden-sm {
    display: none !important;
  }
  .global-scope .form-container {
    padding: 20px;
  }
}
@media (max-width: 991px) {
  .global-scope .hidden-md {
    display: none !important;
  }
}
@media (max-width: 1199px) {
  .global-scope .hidden-lg {
    display: none !important;
  }
}
@media (max-width: 1599px) {
  .global-scope .hidden-xl {
    display: none !important;
  }
}
.global-scope .ant-table-filter-dropdown {
  border-radius: 10px;
}
.global-scope .preview-mask {
  font-weight: normal;
  display: flex;
  align-items: center;
  padding: 5px;
  line-height: 0;
}
.global-scope .preview-mask span[role='img'] {
  margin-right: 5px;
}
